import React from 'react'
import Layout from "../../components/Layout";
import AccountLayout from "../../components/Account/AccountLayout";
import SvgIcon from "../../components/SvgCurve"
import Input from "../../components/Input"

export default function Password() {
    return (
        <Layout>
            <AccountLayout>
                <div className="Password">
                    <span className="Password-title">
                        <SvgIcon className="Password-icon" icon="keyIcon" />
                        Cambiar contraseña
                    </span>
                    <div className="Password-content">
                        <Input label="Introduce tu nueva contraseña" type="password" />
                        <Input label="Confirmar contraseña" type="password" />
                        <button className="Password-btn">Guardar</button>
                    </div>
                </div>
            </AccountLayout>
        </Layout>
    )
}
